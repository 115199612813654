<template>
    <div class="testimonial-area-2 gap-bottom-equal" :class="extraClass" v-if="items.length || carouselItems.length">
        <div class="container edublink-animated-shape">
            <div class="row justify-content-center">
                <div class="col-lg-10 offset-lg-2 d-flex align-items-center">
                    <CommonSectionTitle :title="carouselTitle ? carouselTitle[currentLanguage] : ''" alignment='section-left'/>
                    <h4 v-if="showSeeMore"
                        class="mb-0 ms-auto me-4 text-primary text-uppercase cursor-pointer"
                    >
                        <NuxtLink :to="seeMoreLink">{{ $t('carousel.see_more') }}</NuxtLink>
                    </h4>
                </div>
            </div>
            <div class="blog-gallery-activation">
                <swiper class="testimonial-activation"
                    :modules="modules" 
                    :slides-per-view="1"
                    :loop="items.length > 5 || carouselItems.length > 5"
                    :spaceBetween="0"
                    :autoplay="{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }"
                    :navigation="{ prevEl: '.swiper-btn-nxt-' + index, nextEl: '.swiper-btn-prv-' + index }"
                    :pagination="{ el: '#swiper-pagination-' + index, clickable: true }" 
                    :speed="1000" 
                    :grabCursor="true" 
                    :breakpoints="{
                        '768': {
                            slidesPerView: 2
                        },
                        '992': {
                            slidesPerView: 3
                        }
                    }"
                    ref="target"
                >
                    <swiper-slide :lazy="true" v-for="(item) in items" :key="item.id">
                        <CustomComponentsCarouselCard :item="item" :lazy="true" />
                    </swiper-slide>
                    <swiper-slide :lazy="true" v-if="carouselItems.length" v-for="(item) in carouselItems" :key="item.id">
                        <CustomComponentsCarouselCard :item="item" :lazy="true" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-navigation d-none d-md-block">
                    <div class="swiper-btn-nxt start-0 translate-middle shadow" :class="'swiper-btn-nxt-' + index">
                        <i class="icon-west"></i>
                    </div>
                    <div class="swiper-btn-prv start-100 translate-middle shadow" :class="'swiper-btn-prv-' + index">
                        <i class="icon-east"></i>
                    </div>
                </div>
                <div :id="'swiper-pagination-' + index" class="swiper-pagination d-md-none"></div>
            </div>

            <ul class="shape-group">
                <MouseMove addClassName="shape-1" dataDepth="1.4" imgSrc="/images/about/shape-30.png" data-aos-delay="200"
                    data-aos="fade" data-aos-duration="1000" />
                <MouseMove addClassName="shape-2" dataDepth="-1.4" imgSrc="/images/about/shape-25.png" data-aos-delay="200"
                    data-aos="fade" data-aos-duration="1000" />
            </ul>
        </div>
        <ul class="shape-group">
            <li class="shape-3">
                <img class="d-block-shape-light" data-depth="2" src="/images/others/map-shape-3.png" alt="Shape">
                <img class="d-none-shape-dark" data-depth="2" src="/images/others/dark-map-2-shape-3.png" alt="Shape">
            </li>
        </ul>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useIntersectionObserver } from '@vueuse/core'
    import { Autoplay, Pagination, Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import MouseMove from '../animation/MouseMove.vue'
    import { useMainStore, useLanguageStore } from '~/store'

    export default {
        components: {
            Swiper,
            SwiperSlide,
            MouseMove
        },
        props: {
            category: {
                type: Number,
                required: false,
                default: () => null
            },
            items: {
                type: Array,
                required: false,
                default: () => []
            },
            index: {
                type: Number,
                required: true
            },
            showSeeMore: {
                type: Boolean,
                required: false,
                default: () => false
            },
            seeMoreLink: {
                type: [String, Object],
                required: false,
                default: () => ''
            },
            carouselTitle: {
                type: Object,
                required: false,
                default: () => {
                    return { 'en': '', 'hr': '' }
                }
            },
            extraClass: {
                type: String,
                required: false
            }
        },

        setup() {
            const target = ref(null)
            const targetIsVisible = ref(false)
            const { stop } = useIntersectionObserver(
                target,
                ([{ isIntersecting }], observerElement) => {
                    if (isIntersecting) {
                        target.value.$el.swiper.autoplay.start()
                    } else {
                        target.value.$el.swiper.autoplay.stop()
                    }
                    targetIsVisible.value = isIntersecting
                },
            )

            return {
                modules: [Autoplay, Pagination, Navigation],
                target,
                targetIsVisible
            }
        },

        data() {
            const { locale } = useI18n()

			return {
                currentLanguage: ref(locale.value),
				carouselItems: ref([]),
			}
		},

        beforeMount() {
            this.subscribeToStore()
            this.subscribeToLanguageStore()
        },

        mounted() {
            nextTick(() => {
                setTimeout(() => {
                    this.getCarouselItems()
                }, 1)
            })
        },

        methods: {
            subscribeToStore() {
                const store = useMainStore()
                store.$subscribe((mutation, state) => {
                    if (this.category && store.storeCompletelyLoaded) {
                        this.carouselItems = store.getLocationsByCategory(this.category)
                    }
                })
            },
            subscribeToLanguageStore() {
                const languageStore = useLanguageStore()
                languageStore.$subscribe((mutation, state) => {
                    if (mutation.type == 'patch object' && mutation.payload.currentLanguage) {
                        this.currentLanguage = mutation.payload.currentLanguage
                    }
                })
            },
            getCarouselItems() {
                const store = useMainStore()

                if (this.category) {
                    this.carouselItems = store.getLocationsByCategory(this.category)
                }
            }
        }
    }
</script>

<style lang="scss">
    .testimonial-area-2 {
        padding-bottom: 40px !important;

        .swiper-pagination {
            text-align: center;
            bottom: -65px;
        }

        .swiper-button-disabled {
            visibility: hidden;
        }
    }

    .text-primary {
        color: #33629D !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>