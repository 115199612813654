<template>
    <div class="carousel-card custom-card course-style-3" :class="item.type">
        <div class="inner">
            <div class="thumbnail">
                <NuxtLink v-if="item.images[0]" :to="getCardLink(item)">
                    <img v-if="lazy" :src="item.images[0].thumbnail_path" :alt="item.name[currentLanguage]" loading="lazy">
                    <img v-else :src="item.images[0].thumbnail_path" :alt="item.name[currentLanguage]">
                </NuxtLink>
                <NuxtLink v-else :to="getCardLink(item)">
                    <img v-if="lazy" :src="'https://placehold.co/400x400?text=' + $t('no_image')" :alt="item.name[currentLanguage]" loading="lazy">
                    <img v-else :src="'https://placehold.co/400x400?text=' + $t('no_image')" :alt="item.name[currentLanguage]">
                </NuxtLink>
            </div>
            <div class="content">
                <p class="text-uppercase fw-bold" v-if="parentPlace">{{ parentPlace.name[currentLanguage] }}</p>
                <h5 class="title">
                    <NuxtLink :to="getCardLink(item)">{{ item.name[currentLanguage] }}</NuxtLink>
                </h5>
                <p class="brief-description">{{ item.brief_description[currentLanguage] }}</p>
                <p v-if="item.start_date && item.end_date">
                    {{ formatDate(item.start_date) }} - {{ formatDate(item.end_date) }}
                </p>
                <div class="read-more-btn">
                    <NuxtLink :to="getCardLink(item)" class="edu-btn btn-small btn-secondary">{{ $t('carousel.learn_more') }} <i class="icon-4"></i></NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useMainStore, useLanguageStore } from '~/store'

    export default {
        props: ['item', 'lazy', 'language'],

        data() {
            const { locale } = useI18n()

			return {
                currentLanguage: ref(locale.value),
				parentPlace: ref(null)
			}
		},

        beforeMount() {
            this.getAdditionalData()
            this.subscribeToStore()
            this.subscribeToLanguageStore()
        },

        beforeUpdate() {
            this.getAdditionalData()
        },

        methods: {
            subscribeToStore() {
                const store = useMainStore()
                if (this.item.parent_place_id) {
                    store.$subscribe((mutation, state) => {
                        if (store.isPlacesLoaded && store.isCategoriesLoaded) {
                            this.parentPlace = store.getPlaceById(store.parent_place_id)
                        }
                    })
                }
            },
            subscribeToLanguageStore() {
                const languageStore = useLanguageStore()
                languageStore.$subscribe((mutation, state) => {
                    if (mutation.type == 'patch object' && mutation.payload.currentLanguage) {
                        this.currentLanguage = mutation.payload.currentLanguage
                    }
                })
            },
            getAdditionalData() {
                const store = useMainStore()
                if (this.item.parent_place_id) {
                    this.parentPlace = store.getPlaceById(this.item.parent_place_id)
                }
            },
            formatDate(timestamp) {
                const date = new Date(timestamp * 1000)
                return `${this.padTo2Digits(date.getDate())}.${this.padTo2Digits(date.getMonth()+1)}.${date.getFullYear()}.`
            },
            padTo2Digits(num) {
                return num.toString().padStart(2, '0');
            },
            getCardLink(item) {
                if (item) {
                    return `/${item.type}/${item.id}`
                } else {
                    return 'javascript:;'
                }
            }
        }
    }
</script>